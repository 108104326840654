const observerUp = new IntersectionObserver((entries) => {
  entries.forEach((entry) => {
    if (entry.isIntersecting) {
      entry.target.classList.add("animate-up-after");
    } else {
      entry.target.classList.remove("animate-up-after");
    }
  });
});

const upElements = document.querySelectorAll(".animate-up-before");
upElements.forEach((el) => observerUp.observe(el));

const observerFadeIn = new IntersectionObserver((entries) => {
  entries.forEach((entry) => {
    if (entry.isIntersecting) {
      entry.target.classList.add("animate-fade-in-after");
    } else {
      entry.target.classList.remove("animate-fade-in-after");
    }
  });
});

const fadeElements = document.querySelectorAll(".animate-fade-in-before");
fadeElements.forEach((el) => observerFadeIn.observe(el));

// const leftElements = document.querySelectorAll(".left");
// leftElements.forEach((el) => observer.observe(el));

// const rightElements = document.querySelectorAll(".right");
// rightElements.forEach((el) => observer.observe(el));
